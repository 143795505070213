import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SetupList from './SetupList';
import {FrequencyPrint, SetupCategory, SetupType} from '../common/types';
import PropTypes from 'prop-types';

SetupAccordion.propTypes = {
  frequency: PropTypes.number,
  setups: PropTypes.array,
  setupCategory: PropTypes.number,
  setupUpdateCallback: PropTypes.func
};

export default function SetupAccordion({frequency, setups, setupCategory, setupUpdateCallback}) {


  const [loading, setLoading] = useState(true);
  //const [filterBySetupCategory,setFilterBySetupCategory] = useState(false);
  const [didMount, setDidMount] = useState(false);
  

  // const [mainSetups] = useState(setups);
  
  const [reservoirSetups, setReservoirSetups] = useState(null);
  const [availabilitySetups, setAvailabilitySetups] = useState(null);
  const [turbinePowerSetups, setTurbinePowerSetups] = useState(null);
  const [generatorPowerSetups, setGeneratorPowerSetups] = useState(null);
  
  const [inflowSetups, setInflowSetups] = useState(null);
  const [priceSetups, setPriceSetups] = useState(null);
 
  
  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true);
    filterByCategories();
    setLoading(false); 
  }, [])
  
  const filterByCategories = () => {
    setReservoirSetups(setups.filter(setup => setup.setupType == SetupType.reservoir));
    setAvailabilitySetups(setups.filter(setup => setup.setupType == SetupType.availability));
    setTurbinePowerSetups(setups.filter(setup => setup.setupType == SetupType.turbinepower));
    setGeneratorPowerSetups(setups.filter(setup => setup.setupType == SetupType.generatorpower));
    

    setPriceSetups(setups.filter(setup => setup.setupType == SetupType.price));
    setInflowSetups(setups.filter(setup => setup.setupType == SetupType.inflow));
    //setFilterBySetupCategory(SetupCategory.basicsetup == setupCategory); 
    //setFilterBySetupCategory(true); 
  }

  useEffect(() => { 
      if(didMount){
        filterByCategories();
        //console.log("Setup Accordion: " +  setupCategory + " " +  SetupCategory.basicsetup + " " + didMount);
      }
  }, [setups])

  const isBasicSetup = () => {
    return SetupCategory.basicsetup == setupCategory;
  }

  const isCalculationSetup = () => {
    return SetupCategory.calculation == setupCategory;
  }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
       
       {isBasicSetup() &&
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Reservoir setups ({reservoirSetups.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { reservoirSetups != 'undefined' && reservoirSetups.length > 0 &&
              (<React.Fragment>
               <SetupList setups={reservoirSetups} setupUpdateCallback={setupUpdateCallback}/>
              </React.Fragment>)
          }
         </AccordionDetails>
      </Accordion>
       }


    {isBasicSetup() &&
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Turbine efficiency setups ({turbinePowerSetups.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { turbinePowerSetups != 'undefined' && turbinePowerSetups.length > 0 &&
              (<React.Fragment>
               <SetupList setups={turbinePowerSetups} setupUpdateCallback={setupUpdateCallback}/>
              </React.Fragment>)
          }
         </AccordionDetails>
      </Accordion>
       }

      {isBasicSetup() &&
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Generator efficiency setups ({generatorPowerSetups.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { generatorPowerSetups != 'undefined' && generatorPowerSetups.length > 0 &&
              (<React.Fragment>
               <SetupList setups={generatorPowerSetups} setupUpdateCallback={setupUpdateCallback}/>
              </React.Fragment>)
          }
         </AccordionDetails>
      </Accordion>
       }
        
        {isCalculationSetup() &&
          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Price setups ({priceSetups.length} {FrequencyPrint(frequency)})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {priceSetups != 'undefined' && priceSetups.length > 0 &&
                (<React.Fragment>
                <SetupList setups={priceSetups} setupUpdateCallback={setupUpdateCallback}/>
                </React.Fragment>)
            }
           </AccordionDetails>
        </Accordion>
        }
        {isCalculationSetup() &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Inflow setups ({inflowSetups.length} {FrequencyPrint(frequency)})</Typography>
          </AccordionSummary>
          <AccordionDetails>
          { inflowSetups != 'undefined' && inflowSetups.length > 0 &&
                (<React.Fragment>
                <SetupList setups={inflowSetups} setupUpdateCallback={setupUpdateCallback}/>
                </React.Fragment>)
            }
          </AccordionDetails>
        </Accordion>
        }

      {isCalculationSetup() &&
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Unavailability setups ({availabilitySetups.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { availabilitySetups != 'undefined' && availabilitySetups.length > 0 &&
              (<React.Fragment>
               <SetupList setups={availabilitySetups} setupUpdateCallback={setupUpdateCallback}/>
              </React.Fragment>)
          }
         </AccordionDetails>
      </Accordion>
       }

      </React.Fragment>
  );
}