import React from 'react';
import logo from '../logo.svg';

import '../App-old.css';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

HomePage.propTypes = {
  auth: PropTypes.object
}
export default function HomePage() {
  return(

      <React.Fragment>
      <Card sx={{ margin: 2 }}>
        <CardContent className="App">
        {/* <div className="App"> */}
       <img src={logo} className="App-logo" alt="logo" />
       <Typography sx={{ fontSize: 14, mb: 1.5 }} color="text.primary" gutterBottom>
          Welcome to HydroPowerCalc
        </Typography>
        </CardContent>
        </Card>
        </React.Fragment>
    );
}


//export default Home;
