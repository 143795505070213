import React, { useState ,useEffect, useContext} from "react";
import {AuthContext, UserProfileContext } from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import SetupsDetail from "../setup/SetupsDetail";
import { SetupCategory, Frequency, FrequencyPrint } from '../common/types';
import { ErrorMessage } from '../common/Constant';
import EditCalculationSetup from "./EditCalculationSetup";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CalculationTabs  from './CalculationTabs';
import dayjs from 'dayjs';
import moment from "moment";
import { Alert, Backdrop, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Slider, Stack } from "@mui/material";

export default function AdHocCalculation() {

  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);

  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [calculating, setCalculating] = useState(false);
  const [setupsValidation, setSetupsValidation] = useState({valid:false, errors:[]});
  const [calculationSetup, setCalculationSetup] = useState({frequency: Frequency.Weekly, startDate: dayjs('2014-08-18T00:00:00').toDate, endDate: dayjs('2014-08-18T23:00:00').toDate, startHeight:680, endHeight:680});
  const [frequency, setFrequency] = useState(calculationSetup.frequency);
  const [calculationSetupUpstream, setCalculationSetupUpstream] = useState({frequency: Frequency.Weekly, startDate: dayjs('2014-08-18T00:00:00').toDate, endDate: dayjs('2014-08-18T23:00:00').toDate, startHeight:680, endHeight:680});
  const [calculationSetups,setCalculationSetups] = useState([]);
  
  const [startWithDailyCalc,setStartWithDailyCalc] = useState(false);
  const [dailyCalcNumberOfDays, setDailyCalcNumberOfDays] = useState(7);
  const [dailyCalcNumberOfDaysLabel, setDailyCalcNumberOfDaysLabel] = useState("Start with 7 days daily calculation");

  const [startWithHourlyCalc,setStartWithHourlyCalc] = useState(false);
  const [hourlyCalcNumberOfDays, setHourlyCalcNumberOfDays] = useState(12);
  const [hourlyCalcNumberOfDaysLabel, setHourlyCalcNumberOfDaysLabel] = useState("Start with 12 hours hourly calculation");

  const [calculationSetupBoundaries, setCalculationSetupBoundaries] = useState(null);
  const [calculationSetupValid, setCalculationSetupValid] = useState(true);
  const [calculationResult,setCalculationResult] = useState(null);
  //const [excelBytes,setExcelBytes] = useState(null);
  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});


  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true);
    updateSetupsValidation(calculationSetup.frequency);
  }, [])
  
  useEffect(() => {
    if(didMount && calculationSetupUpstream != null && frequency != calculationSetupUpstream.frequency){
      setFrequency(calculationSetupUpstream.frequency)
      updateSetupsValidation(calculationSetupUpstream.frequency);
      setCalculationResult(null);
    }
  }, [calculationSetupUpstream]);

  useEffect(() => {
    if(didMount){
      setLoading(true);
      updateSetupsValidation(calculationSetup.frequency);
      setCalculationResult(null); // When we change plant, calculationResult needs to be reset.
    }
  }, [userProfile]);

  useEffect(() => {
    if(didMount){
      if(setupsValidation.valid){
        setLoading(true);
        updateCalculationSetupDefaults();
      }else{
        setLoading(false);
      }
    }
  }, [setupsValidation]);

  // useEffect(() => {
  //   if(didMount){
  //     if(setupsValidation.valid){
  //       setLoading(true);
  //       updateCalculationSetupDefaults();
  //     }else{
  //       setLoading(false);
  //     }
  //   }
  // }, [calculationSetupValid]);

  

  const updateSetupsValidation = (frequency) => {
    if(userProfile == null){
      console.log("updateSetupsValidation - userprofile is null");
      return;
    }

    if(userProfile.defaultPlant == null){
      console.log("defaultPlant is null");
      setLoading(false);
      setRejection({open:true, message: "Please setup a plant and import all necessary setups before calculating."});
      return;
    }

    fetch('api/setups/plantsetups/validation/' + userProfile.defaultPlant.id + '?Frequency=' + frequency,
    {
        method: "GET",
        cache: "no-cache",
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authContext.token}`
        }
    })
    .then(response => {
      if(response.ok) {
        response.json().then(validation => {
          setLoading(false);
          setSetupsValidation(validation);
        });
      }else if(response.status == 400){
        response.json().then(error => {
          setLoading(false);
          setRejection({open:true, message: ErrorMessage(error)});
        });
      }else{
        setLoading(false);
        setRejection({open:true, message:"Server error"}); 
      }
    }).catch((err) => {
      console.log(err.message);
      setLoading(false);
      setRejection({open:true, message:"Internal error"});
    });
  }

  const parseFileNameFromContentDisposition = ( contentDisposition ) => {
    const filenamePattern = new RegExp("HPC_Calcresult_.*xlsx;");
    let matches = contentDisposition.match(filenamePattern);
    if(matches == null){
      console.log("Could not parse file name from " + contentDisposition);
      return "ExportFile.xlsx"
    }
    return matches[0].replace(';','');
  }

  const updateCalculationSetupDefaults = () => {
    if(userProfile == null){
      console.log("updateCalculationSetupDefaults - userprofile is null");
      return;
    }

    fetch('api/setups/plantsetups/defaults/' + userProfile.defaultPlant.id + '?Frequency=' + frequency,
      {
          method: "GET",
          cache: "no-cache",
          headers:{
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.token}`
          }
      })
      .then(response => {
        if(response.ok) {
          response.json().then(defaults => {
            setCalculationSetupBoundaries(defaults.calculationSetupBoundaries);
            setCalculationSetup(defaults.calculationUserSetup);
            setCalculationSetupUpstream(defaults.calculationUserSetup);
            setLoading(false);
          });
        }else if(response.status == 400){
          response.json().then(error => {
            setRejection({open:true, message: ErrorMessage(error)});
            setLoading(false);
          });
        }else{
          setRejection({open:true, message:"Server error"}); 
          setLoading(false);
        }
      }).catch((err) => {
        console.log(err.message);
        setRejection({open:true, message:"Internal error"});
        setLoading(false);
    });
  }
  
  // const addToCalculationSetups = () => {
  //   if(userProfile == null){
  //     console.log("addToCalculationSetups - userprofile is null");
  //     return;
  //   }

  //   //Need to treat useState with array as immutable
  //   setCalculationSetups([
  //     ...calculationSetups,
  //     calculationSetupUpstream
  //   ]);
  // }

  const submitCalculation = () => {
    if(userProfile == null){
      console.log("submitCalculation - userprofile is null");
      return;
    }
    setCalculationResult(null);
    setCalculating(true);

    //const url = 'api/Calculator/calculateSequential/' + userProfile.defaultPlant.id;
    const url = 'api/Calculator/adhoc/' + userProfile.defaultPlant.id;
    fetch(url,
      {
          method: "POST",
          cache: "no-cache",
          headers:{
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.token}`
          },
          body: JSON.stringify(calculationSetupUpstream)
      })
      .then(response => {
        if(response.ok) {
          response.json().then(calculationResult => {
            setCalculationResult(calculationResult);
          });
        }
        else if(response.status == 400){
          response.json().then(error => {
            setRejection({open:true, message: ErrorMessage(error)});
          });
        }else{
          setRejection({open:true, message:"Server error"}); 
        }
        setCalculating(false);
      }).catch(() => {
        setRejection({open:true, message:"Internal error"});  
    });
  }

  const submitCalculationSequential = () => {
    if(userProfile == null){
      console.log("submitCalculationMultiple - userprofile is null");
      return;
    }

    //updateCalculationSetupsVolumes();
    let calcSetups = generateCalculationSetups();

    setCalculationResult(null);
    setCalculating(true);

    const url = 'api/Calculator/calculateSequential/' + userProfile.defaultPlant.id;
    fetch(url,
      {
          method: "POST",
          cache: "no-cache",
          headers:{
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.token}`
          },
          body: JSON.stringify(calcSetups)
      })
      .then(response => {
        if(response.ok) {
          response.json().then(calculationResult => {
            setCalculationResult(calculationResult);
          });
        }
        else if(response.status == 400){
          response.json().then(error => {
            setRejection({open:true, message: ErrorMessage(error)});
          });
        }else{
          setRejection({open:true, message:"Server error"}); 
        }
        setCalculating(false);
      }).catch(() => {
        setRejection({open:true, message:"Internal error"});  
    });
  }

  const exportCalculationAsExcel = () => {
    if(userProfile == null){
      console.log("exportCalculation - userprofile is null");
      return;
    }
    
    const url = 'api/Export/calculation/excel/' + userProfile.defaultPlant.id;
    fetch(url,
      {
          method: "POST",
          cache: "no-cache",
          headers:{
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.token}`
          },
          body: JSON.stringify(calculationResult.optimalRoute)
      })
      .then(response => {
        if(response.ok) {
          //response.headers.forEach(console.log);
          const contentDisposition = response.headers.get('content-disposition'); 
          let filename = parseFileNameFromContentDisposition(contentDisposition);
          
          response.blob().then( blob => {
            downloadExcelFile(blob, filename);
          });
          
        }else if(response.status == 400){
          response.json().then(error => {
            setRejection({open:true, message: ErrorMessage(error)});
          });
        }else{
          setRejection({open:true, message:"Server error"}); 
        }
      }).catch((err) => {
        console.log(err.message);
        setRejection({open:true, message:"Internal error"});
    });
  }
  
        
  const getSetupsValidationErrorMessages = () => {
    if(setupsValidation.validations?.length > 0){
              return <Typography sx={{  m: 2, fontSize: 16 }} color="error.main" gutterBottom>
                      Please upload necessary basic and calculation setups to be able to calculate
                    </Typography>
      }
      return "";
    }

  const isValid = () =>{
    return calculationSetupValid && setupsValidation.valid;
  }

  const handleConfirmationClose = () => {
    setConfirmation({open:false,message:""});
  }

  const handleRejectionClose = () => {
    setRejection({open:false,message:""});
  }

  const showCalculationUserSetups = () => {
      return calculationSetups != null && calculationSetups.length > 0;
  }

  // const canAddCalculationSetupToSequential = ()=> {
  //   let setupsLength = calculationSetups.length;
  //   return (setupsLength == 0 && calculationSetupUpstream.frequency != Frequency.Weekly ) ||
  //   (setupsLength == 1 && calculationSetupUpstream.frequency >= Frequency.Daily ) || 
  //   (setupsLength == 2 && calculationSetupUpstream.frequency == Frequency.Weekly )
  // }

  const canSubmitSequentialCalculation = ()=> {
    // let setupsLength = calculationSetups.length;
    // return setupsLength == 2 || setupsLength == 3;
    return isValid() && (startWithDailyCalc || startWithHourlyCalc);
  }

  // const updateCalculationSetupsVolumes = () =>{
  //   if(isValid()){
  //     calculationSetups.map( calcSetup => 
  //       {
  //         calcSetup.startHeight = calculationSetupUpstream.startHeight;
  //         calcSetup.endHeight = calculationSetupUpstream.endHeight;
  //         if(calcSetup.endHeight == undefined){
  //           calcSetup.energyEquivalent = calculationSetupUpstream.energyEquivalent;
  //           calcSetup.lrvWaterValue = calculationSetupUpstream.lrvWaterValue;
  //           calcSetup.hrvWaterValue = calculationSetupUpstream.hrvWaterValue;
  //         }
  //       }
  //     )
  //   }
  // }

  const generateCalculationSetups = () => {
    if(!isValid()){
        return;
    }
    let calcSetups = [];
    let currentCalcSetup = calculationSetupUpstream;
    let weeklyCalcSetup = Object.assign({}, calculationSetupUpstream);
    if(startWithHourlyCalc || startWithDailyCalc){
      if(startWithHourlyCalc){
        let hourlyCalcSetup = Object.assign({}, currentCalcSetup); //Clone current setup
        hourlyCalcSetup.frequency = Frequency.Hourly;
        // Force initial calculations to use WaterValueSetup 
        // to avoid endVolume validation because endVolume is cloned from last calculationUserSetup
        hourlyCalcSetup.endHeight = undefined; 
        hourlyCalcSetup.endDate = dayjs(currentCalcSetup.startDate).add(hourlyCalcNumberOfDays, 'hour');
        currentCalcSetup = hourlyCalcSetup;
        calcSetups.push(hourlyCalcSetup);
      }

      if(startWithDailyCalc){
        let dailyCalcSetup = Object.assign({}, currentCalcSetup); //Clone current setup
        dailyCalcSetup.frequency = Frequency.Daily;
        // Force initial calculations to use WaterValueSetup 
        // to avoid endVolume validation because endVolume is cloned from last calculationUserSetup
        dailyCalcSetup.endHeight = undefined;
        if(startWithHourlyCalc){
          dailyCalcSetup.startDate = dayjs(currentCalcSetup.endDate);           
          dailyCalcSetup.endDate = dayjs(dailyCalcSetup.startDate).add(dailyCalcNumberOfDays, 'day');           
        }else{ // only need to set endDate
          dailyCalcSetup.endDate = dayjs(currentCalcSetup.startDate).add(dailyCalcNumberOfDays, 'day'); 
        }
        currentCalcSetup = dailyCalcSetup;
        calcSetups.push(dailyCalcSetup);
      }
      weeklyCalcSetup.startDate = currentCalcSetup.endDate; 

      calcSetups.push(weeklyCalcSetup);
      setCalculationSetups(calcSetups);
      return calcSetups;
    }
  }
    

    

  const downloadResultFile = () => {
  
      const file = new Blob([JSON.stringify(calculationResult)], {type: 'application/json'});
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      let fileName = "HPC-calculation-" + moment().format('YYYY-MM-DD_HHmm') + ".json";
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
  }

  const downloadExcelFile = (file , filename ) => {
          if(file == null) return;
        
          const url = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = url;
          //let fileName = "HPC-calculation-" + moment().format('YYYY-MM-DD_HHmm') + ".xlsx";
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
  }

  const handleStartWithDailyCalcChange = () =>{
    setStartWithDailyCalc(!startWithDailyCalc);
  }  
  const handleStartWithHourlyCalcChange = () =>{
    setStartWithHourlyCalc(!startWithHourlyCalc);
  }  


  useEffect(() => {
    if(didMount){
      updateDailyLabel(dailyCalcNumberOfDays);
    }
  }, [startWithHourlyCalc]);

  const handleDailyCalcNumberOfDaysInputChange = (e) =>{
    const { value } = e.target;
    setDailyCalcNumberOfDays(value);
    updateDailyLabel(value);
  }
  
  const updateDailyLabel = (value) => {
    let prefix = startWithHourlyCalc ? "Continue with " : "Start with ";
    setDailyCalcNumberOfDaysLabel(prefix + value + " days daily calculation");
  }

  const handleHourlyCalcNumberOfDaysInputChange = (e) =>{
    const { value } = e.target;
    setHourlyCalcNumberOfDays(value);
    setHourlyCalcNumberOfDaysLabel("Start with " + value + " hours hourly calculation");
  }

  return (
    loading ? 
      <Box sx={{ display: 'flex' }}>
            <CircularProgress />
      </Box>
      :
    <React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer +1 }}
        open={calculating}
      >
       <CircularProgress />
      </Backdrop>
      <Snackbar
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      />
       <Snackbar
        open={rejection.open}
        onClose={handleRejectionClose}
      >
        <Alert
          onClose={handleRejectionClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {rejection.message}
        </Alert>
      </Snackbar>
        {userProfile.defaultPlant == null ? 
          <Typography key={userProfile.defaultCompany} sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          This company has no plants
          </Typography>
          :
          (<Box sx={{ m: 0, width: "100%" }}>
          {/* {calculationResult == null && */}
          <SetupsDetail setupCategory={SetupCategory.calculation} defaultFrequency={frequency}/>      
          {/* } */}
          <EditCalculationSetup defaultValues={calculationSetup} boundaries={calculationSetupBoundaries} setValue={setCalculationSetupUpstream} setValid={setCalculationSetupValid}/> 
          <Card sx={{ margin: 2 }}>
            <CardContent>
            <Stack direction="row">
              <Stack>
                <FormGroup>
                  <FormControlLabel disabled={frequency < Frequency.Hourly} control={<Checkbox checked={startWithHourlyCalc} onChange={handleStartWithHourlyCalcChange} />} label={hourlyCalcNumberOfDaysLabel} />
                </FormGroup>   
                <Slider sx={{ width: 280, mb: 1 }}
                      name="numberOfHours"
                      aria-label="Number of hourly calculations"
                      defaultValue={dailyCalcNumberOfDays}
                      valueLabelDisplay="auto"
                      step={1}
                      min={4}
                      max={72}
                      value={hourlyCalcNumberOfDays}
                      onChange={handleHourlyCalcNumberOfDaysInputChange}
                      orientation='horizontal'
                />
              </Stack>
              <Stack>
                <FormGroup>
                  <FormControlLabel disabled={frequency < Frequency.Weekly} control={<Checkbox checked={startWithDailyCalc} onChange={handleStartWithDailyCalcChange} />} label={dailyCalcNumberOfDaysLabel} />
                </FormGroup>   
                <Slider sx={{ width: 280, mb: 1 }}
                      name="numberOfDays"
                      aria-label="Number of daily calculations"
                      defaultValue={dailyCalcNumberOfDays}
                      valueLabelDisplay="auto"
                      step={1}
                      min={4}
                      max={30}
                      value={dailyCalcNumberOfDays}
                      onChange={handleDailyCalcNumberOfDaysInputChange}
                      orientation='horizontal'
                  />
                </Stack>
              </Stack>
            
              <React.Fragment>
                {/* <Button aria-label="addCalculationSetup" disabled={loading || !isValid() || !canAddCalculationSetupToSequential()} onClick={() => addToCalculationSetups()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                      Add date range to calculation setup
                </Button> */}
                
                { canSubmitSequentialCalculation() ? 
                  <Button aria-label="edit" disabled={loading || !canSubmitSequentialCalculation()} onClick={() => submitCalculationSequential()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                    Calculate sequential setups
                  </Button>
                  :
                  <Button aria-label="edit" disabled={loading || !isValid()} onClick={() => submitCalculation()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                    Calculate single setup
                  </Button>
                }
              </React.Fragment>
              {!isValid() &&
                <React.Fragment>
                  {getSetupsValidationErrorMessages()}
                </React.Fragment>
              }
            </CardContent>
          </Card>
          
          { showCalculationUserSetups() && 
            calculationSetups.map( (calcSetup) =>
            (
              <React.Fragment key={calcSetup.id} >
                <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                  Calculating {FrequencyPrint(calcSetup.frequency)} From: {dayjs(calcSetup.startDate).format('DD.MM.YYYY HH:mm')} To: {dayjs(calcSetup.endDate).format('DD.MM.YYYY HH:mm')}
                </Typography>
              </React.Fragment>
            ))
          }

          <React.Fragment>
            {calculationResult != null &&
            <React.Fragment>
              <h4>TotalSeconds: {calculationResult.statistics.calculationTotalSeconds}</h4>
              <CalculationTabs calculationResult={calculationResult}/>
              <Button aria-label="edit" onClick={() => downloadResultFile()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                Download Json result
              </Button>
              <Button aria-label="edit" onClick={() => exportCalculationAsExcel()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                Download Excel result
              </Button>
            </React.Fragment>
            }
          </React.Fragment>
           
          </Box> )
          }
      </React.Fragment>
  );
  }